export const SOFT_CODE = 'image_convert_www_whales'
export const SUB_CODE = 'default'
export const SOFT_CHANNEL = ''
/**
 * 软件配置
 */
export const appConfig = {
  name: '鲸鱼图片转换器',
  logo: '/logo_img.svg',
  contact: {
    customer: 'https://res.yunkun.cn/img_geshicn/img/contact_qr_code_whales.png', // 客服二维码
    officialAccounts: 'https://res.yunkun.cn/img_geshicn/img/qrcode_for_gzh_ks.png', // 公众号二维码
  },
  footer: {
    copyright: `Copyright © ${new Date().getFullYear()} 无锡鲸鱼探索软件有限公司`,
    recordNumber: '苏ICP备2024071471号-1',
    beian: '苏公网安备32021402002552号',
    agreement: '/agreement.html',
    privacyPolicy: '/privacy.html',
    beianLink: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32021402002552'
  },
  companyInfo: {
    name: '昆山云鲲网络科技有限公司',
    address: '/map.png',
    about: {
      title: '/yunkun_logo.png',
      content: [
        {
          title: '关于云鲲',
          content: `<p>云鲲网络是一家致力于数字化办公领域的领先科技公司，我们以卓越的产品和服务为用户提供更好的工作体验，同时也在持续推动着未来办公方式的进步和创新。我们坚信，通过不断的努力和创新，我们能够实现我们的使命，让全球的办公环境变得更加智能、高效和便捷。</p><p>“云”是网络、互联网的一种比喻说法。云计算具有很强的扩展性和需要性，可以为用户提供一种全新的体验。</p><p>“鲲”是传说中的一种大鱼，不是现实存在的动物，具有神秘的力量和象征意义。</p><p>云鲲寓意着我们的愿景：成为数字化办公领域的领头羊，推动办公方式的革命，创造更高效、便捷、智能的办公体验。</p>`
        },
        {
          title: '公司使命',
          content: `<p>云鲲网络的使命是为全球办公人群提供卓越的数字化工具，以提升他们的工作效率、生产力和生活品质。我们深知现代社会办公环境的复杂性和挑战，因此我们的目标是通过创新、可靠的办公工具来解决这些问题，让人们更轻松地完成工作任务，实现工作与生活的平衡。</p><p>云鲲旗下“鲸鱼”品牌，鲸鱼-轻松办公，秒速效率！</p>`
        }
      ]
    }
  },

  allowDownloadDesktop: false, // 是否允许下载桌面客户端
  allowPhoneNumberLogin: false, // 是否允许手机号登录
}